export const fileToBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(removeDataUrl(reader.result))
		reader.onerror = (error) => reject(error)
	})

// Remove dataUrl from base64
export const removeDataUrl = (base64) => base64.split(';')[1].replace('base64,', '')

export interface offerFormSubmitType {
	ssn: string
	name: string
	email: string
	phone: string
	product: ('Posi' | 'Veflausn')[]
	estimated_turnover: number
	estimated_average_transaction: number
	product_or_service_type: string
	organization_website: string
	other_details: string
	pricing_type: 'Blended Pricing' | 'Interchange'
	platform_selection: string[]
	recaptcha: string
	randomMathExpression?: string
	token?: string
}

const sendOfferForm = async (values: offerFormSubmitType) => {
	let body = {
		ssn: values.ssn,
		name: values.name,
		email: values.email,
		phone: values.phone,
		product: values.product,
		estimated_turnover: values.estimated_turnover,
		estimated_average_transaction: values.estimated_average_transaction,
		product_or_service_type: values.product_or_service_type,
		organization_website: values.organization_website,
		other_details: values.other_details,
		pricing_type: values.pricing_type,
		platform_selection: values.platform_selection,
		recaptcha: values.recaptcha,
		randomMathExpression: values.randomMathExpression,
		token: values.token,
	}

	return fetch('/api/offer/', {
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
		method: 'POST',
		body: JSON.stringify(body),
	})
}

export default sendOfferForm
