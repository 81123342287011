import { useAnimation, useInView } from "framer-motion"
import { useEffect, useRef } from "react"

export const useCardViewportScaleAnimation = (min: number = 0.97, max: number = 1 ) => {
	const ref = useRef(null)
	const isInView = useInView(ref)
	const cardControls = useAnimation()

	const variants = {
		visible: { scale: max, transition: { duration: 0.8, delay: 0.2 } },
		hidden: { scale: min },
	}

	useEffect(() => {
		if (isInView) {
			cardControls.start('visible')
		} else {
			cardControls.start('hidden')
		}
	}, [cardControls, isInView])

	return { ref, animate: cardControls, variants }

}
