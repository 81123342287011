import React, { useState } from 'react'
import { map } from 'lodash'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Turnstile from 'react-turnstile'
import DOMPurify from 'dompurify'

import { useCardViewportScaleAnimation } from '../../src/utils/hooks/use-card-viewport-scale-animation'
import RichText from '../../src/components/RichText'
import CardContainer from '../../src/components/CardContainer'
import Select from '../../src/components/Select'
import Input from '../../src/components/Input'
import BasicRoundedButton from '../../src/components/BasicRoundedButton/ButtonLink'
import useLocalization from '../../src/utils/hooks/use-localization'
import { SCALEUP_HOVER, SCALE_DOWN } from '../../src/utils/animation-config'
import { createAlert } from '../../src/providers/notifications/NotificationsProvider'
import Loader from '../../src/components/Loader'
import { devices, media } from '../../src/utils/style-utils'
import { ContactUsSlice } from '../../prismicio-types'
import { backgroundColors } from '../../src/styles/backgroundColors'
import sendContactForm from './send-form'

const ContactUs = ({ slice }: { slice: ContactUsSlice }) => {
	const cardAnimationProps = useCardViewportScaleAnimation()
	const isDefault = slice.variation === 'default'
	const isInquiryOnly = slice.variation === 'inquiryOnly'
	const isComplianceOnly = slice.variation === 'complianceOnly'
	const backgroundColor = backgroundColors(slice.primary?.backgroundColor)
	const { translate } = useLocalization()
	const [isTurnstilePassed, setIsTurnstilePassed] = useState(false)
	const [token, setToken] = useState<string>()

	const CONTACT_US_OPTIONS = [
		{
			value: 'general',
			label: translate('contactForm.inquiryTypes.general'),
		},
		{
			value: 'compliance',
			label: translate('contactForm.inquiryTypes.compliance'),
		},
	]

	return (
		<Container
			{...cardAnimationProps}
			options={{ backgroundColor }}
		>
			<InfoColumn>
				<RichText content={slice.primary?.title} />
				<ContactInfo>
					{map(slice.items, (item, index) => (
						<RichText
							key={index}
							content={item.contactOption}
						/>
					))}
				</ContactInfo>
			</InfoColumn>
			<FormColumn>
				<Formik
					initialValues={{
						requestType: isComplianceOnly ? CONTACT_US_OPTIONS[1] : CONTACT_US_OPTIONS[0],
						name: '',
						email: '',
						message: '',
					}}
					validationSchema={Yup.object().shape({
						name: isInquiryOnly ? Yup.string().required() : Yup.string(),
						email: isInquiryOnly ? Yup.string().email().required() : Yup.string().email(),
						message: Yup.string().required('Required'),
					})}
					onSubmit={async (val) => {
						if (!isTurnstilePassed) {
							return createAlert({
								title: translate('contactForm.error.title'),
								message: translate('contactForm.error.message'),
								type: 'error',
							})
						}
						const cleanVal = {
							name: DOMPurify.sanitize(val.name, { USE_PROFILES: { html: false } }),
							email: DOMPurify.sanitize(val.email, { USE_PROFILES: { html: false } }),
							message: DOMPurify.sanitize(val.message),
							token: token,
						}

						const { ok } = await sendContactForm({
							...cleanVal,
							feedbackType: val.requestType.value,
							subject: '',
						})

						if (!ok) {
							return createAlert({
								title: translate('contactForm.error.title'),
								message: translate('contactForm.error.message'),
								type: 'error',
							})
						}
						return createAlert({
							title: translate('contactForm.success.title'),
							message: translate('contactForm.success.message'),
							type: 'success',
						})
					}}
				>
					{(formikProps) => {
						const { isSubmitting } = formikProps
						const isDisabled = isSubmitting
						return (
							<Form
								onReset={formikProps.handleReset}
								onSubmit={formikProps.handleSubmit}
							>
								{isDefault && (
									<Select
										id='requestType'
										placeholder='Veldu'
										defaultValue={formikProps.values.requestType}
										options={CONTACT_US_OPTIONS}
										isDisabled={isDisabled}
										onChange={(val) => formikProps.setFieldValue('requestType', val)}
									/>
								)}
								<Input
									name='name'
									type='text'
									placeholder={''}
									label={
										isComplianceOnly ? `${translate('contactForm.name')} - valkvætt` : translate('contactForm.name')
									}
									disabled={isDisabled}
									isRequired={isInquiryOnly}
								/>
								<Input
									name='email'
									type='email'
									placeholder={''}
									label={
										isComplianceOnly ? `${translate('contactForm.email')} - valkvætt` : translate('contactForm.email')
									}
									disabled={isDisabled}
									isRequired={isInquiryOnly}
								/>
								<Input
									name='message'
									type='textarea'
									placeholder={translate('contactForm.messagePlaceholder')}
									disabled={isDisabled}
								/>
								<Turnstile
									sitekey={process.env.CLOUDFLARE_TURNSTILE_SITE_KEY ?? ''}
									theme='light'
									onVerify={(token) => {
										setToken(token)
										setIsTurnstilePassed(true)
									}}
								/>
								<Button
									type='submit'
									disabled={isSubmitting}
									whileTap={!isSubmitting && SCALE_DOWN}
									whileHover={!isSubmitting && SCALEUP_HOVER}
								>
									{isSubmitting ? <Loader /> : translate('general.submit')}
								</Button>
							</Form>
						)
					}}
				</Formik>
				<MobileContactInfo>
					{map(slice.items, (item, index) => (
						<RichText
							key={index}
							content={item.contactOption}
						/>
					))}
				</MobileContactInfo>
			</FormColumn>
		</Container>
	)
}

export default ContactUs

const Container = styled(CardContainer)`
	height: 100%;

	${devices.safariDesktop`
		max-height: 780px;
	`}

	${media.tablet`
		display: flex;
		flex-direction: column;
		padding: 12% 7%;
		gap: var(--spacer-md);
	`}
`

const Button = styled(BasicRoundedButton)`
	justify-content: center;
	color: var(--neon);
	:disabled {
		cursor: wait;
		background-color: var(--blue-300);
	}

	${media.desktopHuge`
		height: 72px;
		border-radius: 72px;
	`}
`

const ContactInfo = styled.div`
	margin-top: var(--spacer-md);
	display: flex;
	gap: var(--spacer-md);

	${media.tablet`
		display: none;
	`}
`

const MobileContactInfo = styled.div`
	display: none;
	${media.tablet`
		margin: 0;
		display: flex;
		gap: var(--spacer-md);
		p {
			margin: 0;
		}
	`}
`

const InfoColumn = styled.div`
	grid-column: 2 / span 4;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	margin-top: var(--spacer-base);
	margin-bottom: var(--spacer-base);

	${media.desktopSm`
		grid-column: 2 / span 3;
	`};

	${media.tablet`
		margin: 0;
	`}

	* > h1 {
		margin-top: 0;
		${media.tablet`
			font-size: clamp(2.5rem, 3vw + 1rem, 8.75rem);
			margin: 0.5rem 0rem;
			line-height: clamp(110%, 3vw + 120%, 130%);
		`};
	}

	* > p {
		margin: 0;
	}
`

const FormColumn = styled.div`
	margin-top: var(--spacer-base);
	margin-bottom: var(--spacer-base);
	grid-column: 7 / -2;
	display: flex;
	flex-direction: column;
	gap: var(--spacer-xs);

	${media.desktopSm`
		grid-column: 5 / -2;
	`};

	${media.tablet`
		margin: 0;
	`}

	* > textarea {
		height: 12rem;
	}

	${media.desktopHuge`
		grid-column: 6 / -2;

		* > textarea {
		height: 16rem;
		}

	`}
`

const Form = styled.form`
	display: contents;
`
