import { Field } from 'formik'
import React, { HTMLInputTypeAttribute } from 'react'
import styled, { css } from 'styled-components'

import { devices, media } from '../../utils/style-utils'

interface IInput {
	type?: HTMLInputTypeAttribute
	isRequired?: boolean
	placeholder?: string
	name: string
	label?: string
	disabled?: boolean
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

function Input({ type = 'text', isRequired = false, placeholder, name, label, disabled, onChange }: IInput) {
	const isTextArea = type === 'textarea'
	const hasLabel = !!label && !isTextArea

	return (
		<Field name={name}>
			{({ field, meta: { touched, error } }) => {
				return (
					<InputWrapper
						isTextArea={isTextArea}
						error={touched && error}
						disabled={disabled}
					>
						{hasLabel && (
							<Label htmlFor={name}>
								{label}
								{isRequired && <Required>*</Required>}
							</Label>
						)}
						<StyledInput
							{...field}
							onChange={onChange ?? field.onChange}
							id={name}
							name={name}
							type={type}
							hasLabel={hasLabel}
							placeholder={placeholder}
							disabled={disabled}
							as={!isTextArea ? 'input' : 'textarea'}
						/>
					</InputWrapper>
				)
			}}
		</Field>
	)
}

export default Input

const InputWrapper = styled.div<{ isTextArea: boolean; disabled?: boolean; error?: boolean }>`
	display: flex;
	align-items: center;
	gap: var(--spacer-sm);
	border-radius: 56px;
	background: #ffffff;
	outline: 0px;
	border: 0px;

	width: 100%;
	height: 56px;

	font-family: var(--font-akzidenz);
	font-weight: 300;
	font-size: 1.25rem;
	line-height: 2rem;

	color: var(--black);
	::placeholder {
		color: var(--input-text-color);
	}

	&:hover {
		outline: 2px solid var(--blue-300);
	}

	&:focus-within {
		outline: 2px solid var(--blue-500);
	}

	${devices.safariDesktop`
		border: 2px solid transparent;
		outline: 0 !important;
		&:focus-within {
			outline: 0;
			border: 2px solid var(--blue-500);
		}

		&:hover {
			outline: 0;
			border: 2px solid var(--blue-300);
		}
	`};

	${(p) =>
		p.disabled &&
		css`
			background: hsl(0, 0%, 95%);
			cursor: wait;

			&:hover {
				outline: 0;
			}

			&:focus-within {
				outline: 0;
			}
		`}

	${(p) =>
		p.error &&
		css`
			outline: 2px solid var(--danger-red-500);
			background: var(--danger-red-100);

			${devices.safariDesktop`
				border: 2px solid var(--danger-red-500);
				background: var(--danger-red-100);
			`}
		`}

	transition: outline 0.1s;

	${(p) =>
		p.isTextArea &&
		css`
			height: unset;
			min-height: 216px;
			align-items: flex-start;
			padding-top: 12px;
			border-radius: 24px;
		`}

	${media.desktopHuge`
		height: 72px;
	`}
`

const Label = styled.label`
	color: var(--input-text-color);
	padding-left: 26px;
	white-space: nowrap;

	${media.tablet`
		font-size: 1rem;
	`}
`

const Required = styled.span`
	color: var(--orange);
`

const StyledInput = styled.input<{ hasLabel?: boolean; onChange: Function }>`
	all: unset;
	height: 100%;
	width: 100%;

	padding-left: ${(p) => (p.hasLabel ? '1px' : '26px')};
	padding-right: var(--spacer-xs);

	${media.tablet`
		font-size: 1rem;
	`}
`
