import { motion } from 'framer-motion'
import styled from 'styled-components'

import { SCALEUP_HOVER } from '../../utils/animation-config'
import { media } from '../../utils/style-utils'

const BasicRoundedButton = (props) => (
	<Button
		whileHover={SCALEUP_HOVER}
		{...props}
	/>
)

const Button = styled(motion.button)`
	cursor: pointer;
	display: flex;
	align-items: center;
	font-family: var(--font-atyp-display);
	background: var(--deep-blue);
	border: none;
	border-radius: 30px;
	color: white;
	padding: 16px 32px;
	text-align: center;
	height: 56px;
	font-size: 1.125rem;

	${media.phone`
		width: 100%;
	`}

	&:hover,
	:focus {
		background-color: var(--blue-700);
	}

	&:focus-visible {
		outline: 3px solid var(--orange);
	}
`

export default BasicRoundedButton
