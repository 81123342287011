import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

const DOT_VARIANTS = {
	initial: {
		y: 3,
	},
	animate: {
		y: -8,
	},
}

const DOT_TRANSITION = {
	duration: 0.3,
	repeat: Infinity,
	repeatType: 'reverse',
	ease: 'easeInOut',
}

const CONTAINER_VARIANTS = {
	initial: {
		transition: {
			staggerChildren: 0.2,
		},
	},
	animate: {
		transition: {
			staggerChildren: 0.2,
		},
	},
}

function Loader() {
	return (
		<LoaderWrapper
			initial='initial'
			animate='animate'
			variants={CONTAINER_VARIANTS}
		>
			<Dot
				variants={DOT_VARIANTS}
				// @ts-ignore // This seems to be a bug caused by the framer-motion types
				transition={DOT_TRANSITION}
			/>
			<Dot
				variants={DOT_VARIANTS}
				// @ts-ignore
				transition={DOT_TRANSITION}
			/>
			<Dot
				variants={DOT_VARIANTS}
				// @ts-ignore
				transition={DOT_TRANSITION}
			/>
		</LoaderWrapper>
	)
}

export default Loader

const LoaderWrapper = styled(motion.div)`
	display: flex;
	gap: var(--spacer-2xs);
	align-items: center;
	max-height: 56px;
`
const Dot = styled(motion.span)`
	width: 12px;
	height: 12px;
	border-radius: 50%;
	display: block;
	background-color: var(--blue-400);
`
