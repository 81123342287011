import { set } from 'lodash'

import { fileToBase64 } from '../../src/utils/form-utils'

const sendContactForm = async (values: {
	name: string
	email: string
	subject: string
	message: string
	feedbackType: string
	files?: File[]
	recaptcha?: string
	token?: string
}) => {
	let body = {
		name: values.name,
		email: values.email,
		subject: values.subject,
		message: values.message,
		feedbackType: values.feedbackType,
		company: 'Straumur',
		token: values.token,
	}

	if (values.files) {
		const filesArr = await Promise.all(
			values.files.map(async (file) => ({
				content_type: file ? file?.type : undefined,
				content: file ? await fileToBase64(file) : undefined,
				file_name: file ? file.name : undefined,
			}))
		)
		set(body, 'files', filesArr)
	}

	return fetch('/api/contact-us/', {
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
		method: 'POST',
		body: JSON.stringify(body),
	})
}

export default sendContactForm
