import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { useCardViewportScaleAnimation } from '../../utils/hooks/use-card-viewport-scale-animation'
import { CardPadding, CardStyles } from '../Card/card-styles'
import { FullScreenCardGridStyles } from '../../layout/grid/Grid'
import { media } from '../../utils/style-utils'

function CardContainer({
	children,
	options,
	className,
	animation = true,
}: {
	children: React.ReactNode
	options: { backgroundColor: string }
	className?: string
	animation?: boolean
}) {
	const cardViewportScaleAnimation = useCardViewportScaleAnimation()
	const cardAnimationProps = animation ? cardViewportScaleAnimation : undefined
	return (
		<Container
			className={className}
			{...cardAnimationProps}
			{...options}
		>
			{children}
		</Container>
	)
}

export default CardContainer

const Container = styled(motion.section)<{ backgroundColor: string }>`
	${CardStyles}
	${FullScreenCardGridStyles}

	${media.tablet`
		padding-top: ${CardPadding};
		padding-bottom: ${CardPadding};
	`}

	${media.phone`
		padding-top: 64px;
		padding-bottom: 64px;
	`}
`
