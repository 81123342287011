export const SCALEUP_HOVER = {
	scale: 1.05,
	transition: { duration: 0.1, ease: 'easeInOut' },
}

export const SCALE_DOWN = {
	scale: 0.95,
	transition: { duration: 0.1, ease: 'easeInOut' },
}

export const CARD_SCALEDOWN_HOVER = {
	scale: 0.98,
	transition: { duration: 0.8, ease: 'easeInOut' },
}

export const CARD_SCALEUP_HOVER = {
	scale: 1.02,
	transition: { duration: 0.8, ease: 'easeInOut' },
}